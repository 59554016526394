import qS from 'query-string';
import React from 'react';
import ReactDOM from 'react-dom';
import { Widget } from './components/Widget';
import './init';

export const run = () => {
  const search = qS.parse(location.search);
  const { widgetId, href } = search;
  if (!widgetId || Array.isArray(widgetId) || !href || Array.isArray(href)) {
    return;
  }

  ReactDOM.render(
    <Widget widgetId={widgetId} href={href}/>,
    document.getElementById('root')
  );
};

run();
