import axios from 'axios';
import qS from 'query-string';
import * as ENV from '../../env.json';

interface IClickParams {
  productId: string;
  widgetId: string;
  userId: string;
  href: string;
}

const noop = () => {};

export const trackClick = (params: IClickParams): void => {
  const query = qS.stringify({
    ...params,
    href: encodeURIComponent(params.href)
  });
  const url = `${ENV.cloudFunctionsDomain}/tracking-trackProductClick`;
  axios.get(`${url}?${query}`).then(noop, noop);
};
