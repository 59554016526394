import React from 'react';

interface IProps {
  onClick: () => void;
  children: any;
}

export const TrackClick = ({ onClick, children }: IProps) => {
  const onMouseUp = (ev: React.MouseEvent) => {
    if (ev.button === 0 || ev.button === 1) {
      onClick();
    }
  };
  return <div onMouseUp={onMouseUp}>{children}</div>;
};
