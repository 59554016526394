import firebase from 'firebase/app';

export type Document<T> = {
  id: string;
  data: T;
};

export const toDoc = <T>(
  d:
    | firebase.firestore.QueryDocumentSnapshot
    | firebase.firestore.DocumentSnapshot
): Document<T> => ({
  id: d.id,
  data: d.data() as T
});
