import React from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { toDoc } from '../../domainTypes/document';
import { IVerticalCards } from '../../domainTypes/widgets/vertical-cards';
import { db } from '../../services/db';
import { trackClick } from '../../services/tracking';
import { VerticalCards } from './VerticalCards';

interface IProps {
  widgetId: string;
  href: string;
}

export const Widget = ({ widgetId, href }: IProps) => {
  const { loading, value, error } = useDocument(
    db()
      .collection('widgets')
      .doc(widgetId)
  );
  if (loading) {
    return <div>Loading...</div>;
  }
  if (value) {
    const doc = toDoc<IVerticalCards>(value);
    const onClick = (productId: string) =>
      trackClick({
        productId,
        widgetId: doc.id,
        userId: doc.data.userId,
        href
      });
    return <VerticalCards widgetProps={doc.data} onClick={onClick} />;
  }

  return null;
};
