import styled from '@emotion/styled';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { IVerticalCards } from '../../../domainTypes/widgets/vertical-cards';
import { TrackClick } from '../../TrackClick';

interface Props {
  classes?: any;
  widgetProps: IVerticalCards;
  onClick: (productId: string) => void;
}

interface WrapperProps {
  count?: number;
}

const Wrapper = styled('div')<WrapperProps>`
  margin: 12px;
  width: ${(props: WrapperProps) => `calc(100% - ${100 / (props.count || 1)})`};
  max-width: 375px;
`;

export function VerticalCards(props: Props) {
  const { widgetProps, onClick } = props;
  const { items, settings } = widgetProps;
  const count = items.length;

  return (
    <div style={{ display: 'flex' }}>
      {items.map((item, i) => (
        <Wrapper count={count} key={i}>
          <Card>
            <TrackClick onClick={() => onClick(item.productId!)}>
              <CardActionArea href={item.url} target="_blank" rel="nofollow">
                <CardMedia
                  style={{ height: `${settings.height}px` }}
                  image={
                    item.image ||
                    '/images/widget-previews/blank-product-image.png'
                  }
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {item.name}
                  </Typography>
                  <Typography component="p">{item.description}</Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary" href={item.url}>
                  Shop item
                </Button>
              </CardActions>
            </TrackClick>
          </Card>
        </Wrapper>
      ))}
    </div>
  );
}
